import { render, staticRenderFns } from "./order-creator-multiple.vue?vue&type=template&id=b634ac26&scoped=true&"
import script from "./order-creator-multiple.vue?vue&type=script&lang=ts&"
export * from "./order-creator-multiple.vue?vue&type=script&lang=ts&"
import style0 from "./order-creator-multiple.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b634ac26",
  null
  
)

export default component.exports