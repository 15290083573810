







































































































































    import PageHeader from '~/components/app/page-header.vue'
    import OfflineClassStructuredDisplay from '~/components/course/offline-class-structured-display.vue'
    import {AddOrder, AddOrderCourse, Course, OrderStatus} from '~/components/data-class/data-class'
    import statusOptions from '~/components/order/status'
    import {createRequest} from '~/utils/network-request'
    import copy from 'copy-to-clipboard'
    import _ from 'lodash'
    import {Component, Vue} from 'vue-property-decorator'

    @Component({
        components: {
            PageHeader,
            OfflineClassStructuredDisplay
        }
    })
    export default class OrderCreatorMultiple extends Vue {
        name: 'order-creator'

        selectedCourseId: string = ''
        selectedCourse: Course = new Course()
        selectedClassId: number = -1
        selectedStatus: OrderStatus = OrderStatus.PAID
        phoneNumbers: string = ''
        addOrderError: string = ''

        addOrderRecords: {
            phone: string,
            memberId: string,
            success: boolean | null
        }[] = []

        showFailedResultsOnly = false
        statusOptions = statusOptions

        get failedPhone() {
            return _.map(this.addOrderRecords.filter((r) => !r.success), 'phone').join('\n')
        }

        async created() {

        }

        async backClicked() {
            await this.$router.push('/orders')
        }

        async createClicked() {
            this.addOrderError = ''

            let errorStrings: string[] = []

            if (this.selectedCourseId === '') {
                errorStrings.push('Please select a course')
            }

            if (this.selectedCourse.offline && this.selectedClassId === -1) {
                errorStrings.push('Please select a class')
            }

            if (this.phoneNumbers === '') {
                errorStrings.push('Please input at least one phone number')
            }

            if (errorStrings.length == 0) {
                // Create orders

// .filter(p => p.length === 8)
                // if (this.addOrderModel.phone != '' && this.addOrderModel.phone.length != 8) {
                //     errorStrings.push('Phone number must be of length 8')
                // }

                const phones = this.phoneNumbers.split('\n').filter((p) => p.length !== 0)
                const res = await createRequest('/phones-to-member-id', 'post', {}, {
                    'phones': phones.map((p) => p.replace(/\D/g, '').trim())
                }).send()
                const memberIds: string[] = res.data.member_ids

                this.addOrderRecords = phones.map((phone, i) => {
                    return {
                        phone: phone,
                        memberId: memberIds[i],
                        success: null
                    }
                })


                // Create order content. Everything except the member id should be the same for every student.
                const addOrder = new AddOrder()
                const addCourse = new AddOrderCourse()
                addCourse.course_id = this.selectedCourseId
                addCourse.price = 0
                addCourse.note = false
                addOrder.courses = [
                    addCourse
                ]

                addOrder.price = 0
                addOrder.shipping_code = ''
                addOrder.phone = ''
                addOrder.waybill_number = ''
                addOrder.status = this.selectedStatus
                addOrder.offline_data[this.selectedCourseId] = {
                    name: '',
                    email: '',
                    phone: '',
                    time_slot: this.selectedClassId
                }

                for (const [i, memberId] of memberIds.entries()) {
                    if (memberId.length === 0) {
                        this.addOrderRecords[i].success = false
                    } else {
                        addOrder.member_id = memberId
                        try {
                            await createRequest('/courses/order/member/' + memberId + '/force', 'post', {}, addOrder).send()
                            this.addOrderRecords[i].success = true
                        } catch (e) {
                            this.addOrderRecords[i].success = false
                        }
                    }
                }

                this.$message(`Process completed, ${this.addOrderRecords.filter((r) => !r.success).length} failed!`)
            } else {
                this.addOrderError = errorStrings.join(', ')
            }
        }

        courseChanged() {
            this.selectedClassId = -1
        }

        addOrderResultData(successful: boolean | null) {
            if (successful === true) {
                return {
                    title: 'Success',
                    cssClass: 'text-primary border-primary'
                }
            }
            if (successful === false) {
                return {
                    title: 'Fail',
                    cssClass: 'text-danger border-danger'
                }
            }
            return {
                title: 'In Progress',
                cssClass: 'text-grey-700 border-grey-600'
            }
        }

        clickCopyFailed() {
            copy(this.failedPhone, {
                debug: false,
                message: 'Press #{key} to copy',
            })

            this.$message({
                type: 'info',
                message: 'Failed phone number copied'
            })
        }
    }
